import Products from "../../Products/Products";
import productsData from "../../../data/products.json"; // Import the local JSON data

const RelatedProducts = ({ productId, categoryId }) => {
    // Filter related products: same category, but exclude the current product
    const relatedProducts = productsData.filter(
        (product) => 
            product.category_id === categoryId && // Check if category_id matches
            product.id !== productId // Exclude the current product
    ).slice(0, 4); // Limit to 4 products

    return (
        <div className="related-product">
            {relatedProducts.length > 0 ? (
                <Products headingText="Related Items" products={relatedProducts} />
            ) : (
                <div>No related products available</div> // Show a message if no related products are found
            )}
        </div>
    );
};

export default RelatedProducts;
