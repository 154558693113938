import React from "react";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa";
import "./SingleProduct.scss";

import { useParams } from "react-router-dom";
import productsData from "../../data/products.json"; // Import the local JSON data

const SingleProduct = () => {
    const { id } = useParams();

    // Find the product by its id from the local JSON data
    const product = productsData.find((product) => product.id === parseInt(id));

    // If no product is found, return a message
    if (!product) return <div>Product not found</div>;

    // Construct the product image URL directly from JSON
    const productImageUrl = product.img;

    const handleAddToCart = () => {
        const message = `Hello, I am interested in buying ${product.title}. Here is the link to the product image: ${productImageUrl}`;
        const phoneNumber = "+447983429797"; // Replace with your WhatsApp number in international format without + sign
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

        // Open WhatsApp chat
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="single-product-main-content">
            <div className="layout">
                <div className="single-product-page">
                    <div className="left">
                        {productImageUrl ? (
                            <img src={productImageUrl} alt={product.title} />
                        ) : (
                            <div>No image available</div>
                        )}
                    </div>
                    <div className="right">
                        <span className="name">{product.title}</span>
                        <span className="desc">{product.desc}</span>
                        <div className="cart-button">
                            <button className="add-to-cart-button" onClick={handleAddToCart}>
                                Talk to an expert
                            </button>
                        </div>
                        <span className="divider" />
                        <div className="info-item">
                            <span className="text-bold">
                                Category: <span>{product.category_id}</span> {/* Display category id or title */}
                            </span>
                            <span className="text-bold">
                                Share:
                                <span className="social-icons">
                                    <FaFacebook size={16} />
                                    <FaInstagram size={16} />
                                    <FaPinterest size={16} />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <RelatedProducts productId={parseInt(id)} categoryId={product.category_id} />
            </div>
        </div>
    );
};

export default SingleProduct;
