import { useParams } from "react-router-dom";
import productsData from '../../data/products.json'; // Import the products data
import categoriesData from '../../data/categories.json'; // Import the categories data
import "./Category.scss";
import Products from "../Products/Products"; // Correct the import path

const Category = () => {
    const { id } = useParams(); // Get the category ID from the URL
    console.log("Category ID:", id); // Log the category ID to the console

    // Find the current category using the ID
    const currentCategory = categoriesData.find(category => category.id === Number(id));
    console.log("Current Category:", currentCategory); // Log the current category to the console

    // Filter products that belong to the current category using the category_id
    const filteredProducts = productsData.filter(product => product.category_id === Number(id));
    console.log("Filtered Products:", filteredProducts); // Log filtered products

    return (
        <div className="category-main-content">
            <div className="layout">
                <div className="category-title">
                    {currentCategory ? currentCategory.title : "Category not found"}
                </div>

                {filteredProducts.length > 0 ? (
                    <Products innerPage={true} products={filteredProducts} />
                ) : (
                    <div>No products available</div> // Show this if no products match the category
                )}
            </div>
        </div>
    );
};

export default Category;
