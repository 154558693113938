import React, { useEffect, useContext, useState } from "react";
import "./Home.scss";
import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import Products from "../Products/Products";
import productsData from "../../data/products.json"; // Adjust the path as needed
import categoriesData from "../../data/categories.json"; // Adjust the path as needed
import { Context } from "../../utils/context"; // Ensure the correct import path

const Home = () => {
    const { setCategories, setProducts } = useContext(Context);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // Load local data
        const loadLocalData = () => {
            try {
                setProducts(productsData); // Set products from local JSON
                setCategories(categoriesData); // Set categories from local JSON
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false); // Set loading to false after data is loaded
            }
        };

        loadLocalData(); // Load data when the component mounts
    }, [setProducts, setCategories]); // Use only stable dependencies

    if (loading) {
        return <div>Loading...</div>; // Display loading state
    }

    return (
        <div>
            <Banner />
            <div className="main-content">
                <div className="layout">
                    <Category categories={categoriesData} /> {/* Pass categoriesData directly */}
                    <Products headingText="Popular Products" products={productsData} /> {/* Pass productsData directly */}
                </div>
            </div>
        </div>
    );
};

export default Home;
