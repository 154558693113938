import React from 'react';
import { useNavigate } from "react-router-dom";
import "./Banner.scss";

const Banner = () => {
    const navigate = useNavigate();

    return (
        <section className="hero">
            {/* Add playsInline attribute for iOS */}
            <video 
                className="hero-video" 
                src="/assets/banner video.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
            />
            <div className="hero-content">
                <h1>Welcome to Junaid Jewellers</h1>
                <p>Discover our exquisite collection of gold and silver jewelry.</p>
                <button onClick={() => navigate('/about')}>About Us</button>
            </div>
        </section>
    );
};

export default Banner;
