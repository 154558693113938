import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import Newsletter from "./components/Footer/Newsletter/Newsletter";
import AppContext from "./utils/context"; 
import Category from "./components/Category/Category";
import About from './components/About/About';
import ScrollToTopOnRouteChange from './components/ScrollToTopOnRouteChange'; // Import the ScrollToTopOnRouteChange component

function App() {
    return (
        <AppContext> 
            <BrowserRouter>
                <Header />
                <ScrollToTopOnRouteChange /> {/* Add this component to enable scroll to top on route change */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/product/:id" element={<SingleProduct />} />
                </Routes>
                <Newsletter />
                <Footer />
            </BrowserRouter>
        </AppContext>
    );
}

export default App;
