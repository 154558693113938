// src/components/ScrollToTop/ScrollToTop.jsx
import React, { useState, useEffect } from "react";
import { FiArrowUp } from "react-icons/fi";
import "./ScrollToTop.scss"; 

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div className={`scroll-to-top ${visible ? "show" : ""}`}>
            <div onClick={scrollToTop}>
                <FiArrowUp />
            </div>
        </div>
    );
};

export default ScrollToTop;
