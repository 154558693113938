import { useState } from "react";
import { MdClose } from "react-icons/md";
import "./Search.scss";
import { useNavigate } from "react-router-dom";
import productsData from "../../../data/products.json"; // Import the local JSON data

const Search = ({ setShowSearch }) => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  // Filter products based on the search query
  const filteredProducts = productsData.filter(product =>
    product.title.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="search-model">
      <div className="form-field">
        <input
          type="text"
          autoFocus
          placeholder="Search Here"
          value={query}
          onChange={onChange}
        />
        <MdClose onClick={() => setShowSearch(false)} />
      </div>
      <div className="search-result-content">
        <div className="search-results">
          {filteredProducts.map(item => (
            <div key={item.id} className="search-result-item" onClick={() => {
              navigate("/product/" + item.id);
              window.location.reload(); // Reload the page
              setShowSearch(false);
            }}>
              <div className="img-container">
                <img src={item.img} alt={item.title} />
              </div>
              <div className="prod-details">
                <span className="name">{item.title}</span>
                <span className="desc">{item.desc}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
