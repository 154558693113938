import { useNavigate } from 'react-router-dom';
import categoriesData from '../../../data/categories.json'; // Ensure this path is correct
import "./Category.scss";

const Category = () => {
    const navigate = useNavigate();
    
    return (
        <div className="shop-by-category">
            <div className="categories">
                {categoriesData?.map((item) => (
                    <div key={item.id} className="category" onClick={() => navigate(`/category/${item.id}`)}>
                        <img src={item.img} alt={`Category ${item.id}`} /> {/* Added alt text for accessibility */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Category;
