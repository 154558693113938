import React from "react";
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
} from "react-icons/fa";
import "./Newsletter.scss";

const Newsletter = () => {
    // Directly set the URL of the newsletter background image
    const newsletterBg = '/assets/newsletter-bg.jpeg'; // Adjust this path if necessary

    return (
        <div className="newsletter-section" style={{ backgroundImage: `url(${newsletterBg})` }}>
            <div className="newsletter-content">
                <span className="small-text">Newsletter</span>
                <span className="big-text">Sign up for latest updates and offers</span>
                <div className="form">
                    <input type="text" placeholder="Email Address" />
                    <button>Subscribe</button>
                </div>
                <div className="text">Will be used in accordance with our Privacy Policy</div>
                <div className="social-icons">
                    <div className="icons">
                        <FaFacebook fontSize={14} />
                    </div>
                    <div className="icons">
                        <FaInstagram fontSize={14} />
                    </div>
                    <div className="icons">
                        <FaTwitter fontSize={14} />
                    </div>
                    <div className="icons">
                        <FaLinkedinIn fontSize={14} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
