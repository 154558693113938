import React, { createContext, useState } from "react";

export const Context = createContext();

const AppContext = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);

    const value = { categories, setCategories, products, setProducts };
    console.log('Providing Context:', value); // Log the provided context

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
};

export default AppContext;
