// src/components/About/About.jsx
import React from 'react';
import "./About.scss";

const About = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <h1>About Us</h1>
                <div className="about-text">
                    <p>
                        Junaid Jewellers is one of the leading manufacturers in the United Kingdom. Company established by Mr. Muhammad Iqbal in 1974 in London city, located at north-west of the United Kingdom, as a small retailer and manufacturer.
                    </p>
                    <p>
                        Till the day the company has been established, it serves the jewelry sector as a designer and manufacturer. Working with the jewelers from different cities of the United Kingdom, experienced Junaid Jewellers about the jewelry culture and different production techniques. This also contributed to the development of both the jewelry sector and the country’s economy.
                    </p>
                    <p>
                        Since 1974 Mr. Iqbal has expanded his business and now Junaid Jewellers exports silver and gold jewels to countries like Kuwait, Turkey, and the United Arab Emirates.
                    </p>
                    <p>
                        As it has been in our history; today Junaid Jewellers is serving you different designs with competitive prices, high quality, fast service, and guarantee for generations to come.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
