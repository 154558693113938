import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TbSearch } from "react-icons/tb";
import { FiPhone, FiMapPin, FiMenu, FiX, FiArrowUp } from "react-icons/fi"; // Import necessary icons
import { CgShoppingCart } from "react-icons/cg";

import Search from "./Search/Search";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./Header.scss";

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const navigate = useNavigate();

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = "+447983429797"; // Replace with your phone number
        const message = "Hello, I am interested in buying gold bangles. Here is the link to the product image: http://localhost:1337/uploads/bangle_2_e316dcc87a.png";
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, "_blank");
    };

    const handleLocationClick = () => {
        const locationUrl = "https://www.google.com/maps?q=Junaid Jewellers, 304 Farnham Rd, Slough SL1 4XL, United Kingdom"; // Replace with your actual coordinates
        window.open(locationUrl, "_blank");
    };

    return (
        <>
            <header className={`main-header ${scrolled ? "sticky-header" : ""}`}>
                <div className="header-content">
                    <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                        {showMobileMenu ? <FiX /> : <FiMenu />}
                    </div>
                    <ul className={`left ${showMobileMenu ? "show" : ""}`}>
                        <li onClick={() => { navigate("/"); setShowMobileMenu(false); }}>Home</li>
                        <li onClick={() => { navigate("/about"); setShowMobileMenu(false); }}>About</li>
                        <li
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="category-menu"
                        >
                            Category
                            {showDropdown && (
                                <ul className="dropdown">
                                    <li onClick={() => { navigate("/category/1"); setShowMobileMenu(false); }}>Gold Sets</li>
                                    <li onClick={() => { navigate("/category/2"); setShowMobileMenu(false); }}>Bangles</li>
                                    <li onClick={() => { navigate("/category/3"); setShowMobileMenu(false); }}>Gold Rings</li>
                                    <li onClick={() => { navigate("/category/4"); setShowMobileMenu(false); }}>Gold Necklaces</li>
                                </ul>
                            )}
                        </li>
                    </ul>
                    <div className="center" onClick={() => navigate("/")}>
                        JUNAID JEWELLERS
                    </div>
                    <div className="right">
                        <TbSearch onClick={() => setShowSearch(true)} />
                        <FiMapPin onClick={handleLocationClick} style={{ cursor: 'pointer' }} />
                        <FiPhone onClick={handleWhatsAppClick} style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            </header>

            {showSearch && <Search setShowSearch={setShowSearch} />}
            <ScrollToTop />
        </>
    );
};

export default Header;
