import "./Products.scss"; 
import Product from "./Product/Product";

const Products = ({ products, innerPage, headingText }) => {
    return (
        <div className="products-container">
            {!innerPage && <div className="section-heading">{headingText}</div>}
            <div className="products">
                {products.length > 0 ? (
                    products.map(item => (
                        <Product key={item.id} id={item.id} data={item} />
                    ))
                ) : (
                    <div>No products available</div> // Show a message if no products are found
                )}
            </div>
        </div>
    );
};

export default Products;
