import { useNavigate } from "react-router-dom";
import "./Product.scss";

const Product = ({ id, data }) => {
    const navigate = useNavigate();

    // Ensure the correct image URL without leading slash if not in the public directory
    const productImageUrl = data.img; // The path in JSON should include `/assets/products/`

    return (
        <div className="product-card" onClick={() => navigate("/product/" + id)}>
            <div className="thumbnail">
                <img src={productImageUrl} alt={data?.title || "Product"} /> {/* Fallback alt text */}
            </div>
            <div className="prod-details">
                <span className="name">{data?.title || "No Title"}</span> {/* Fallback title */}
            </div>
        </div>
    );
};

export default Product;
